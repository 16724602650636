import { useMemo } from 'react';

import { useGetPricesQuery } from '~/services/joyline';

export const useGetPrices = () => {
  const { data: prices } = useGetPricesQuery();

  const monthlyPrice = useMemo(() => {
    return prices?.find((price) => price.billingPeriod === 'monthly')?.amount;
  }, [prices]);

  const yearlyPrice = useMemo(() => {
    return prices?.find((price) => price.billingPeriod === 'yearly')?.amount;
  }, [prices]);

  return {
    monthlyPrice: monthlyPrice ?? 0,
    yearlyPrice: yearlyPrice ?? 0,
    yearlyMonthlyPrice: (yearlyPrice ?? 0) / 12,
  };
};
