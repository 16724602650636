import { FC } from 'react';
import Image from 'next/image';
import { SocialIcon } from 'react-social-icons';

import { Box, Flex, Stack, useTheme } from '@chakra-ui/react';

import { FooterNav } from '~/components/footer-nav';
import { Logo } from '~/components/logo';

export const Footer: FC = () => {
  const theme = useTheme();

  return (
    <Box as='footer' bgGradient='linear(to-br, #FEFCEA, #E9FEF4)' py={8}>
      <Stack spacing={4}>
        <Image src='/images/logo-mark.svg' width={35} height={36} alt='Joyline' />
        <Flex gap={3} justifyContent='center'>
          <SocialIcon
            url='https://twitter.com/joyline_io'
            bgColor={theme.colors.green}
            fgColor='white'
            style={{ width: 32, height: 32 }}
          />
          <SocialIcon
            url='https://www.instagram.com/joyline.io/'
            bgColor={theme.colors.green}
            fgColor='white'
            style={{ width: 32, height: 32 }}
          />
          <SocialIcon
            url='https://www.facebook.com/Joyline-101101666035591'
            bgColor={theme.colors.green}
            fgColor='white'
            style={{ width: 32, height: 32 }}
          />
        </Flex>
        <FooterNav colorScheme='green' />
      </Stack>
    </Box>
  );
};
