import { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useWindowSize } from 'usehooks-ts';

import { Box, Button, Container, Flex, Heading, Text, useTheme, VStack } from '@chakra-ui/react';

import { ContentContainer } from '~/components/containers';
import { verticalSectionSpacing } from '~/styles';

export const Hero: FC = () => {
  const theme = useTheme();

  const { width } = useWindowSize();

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setImageLoaded(true);
    }, 500);
  }, []);

  return (
    <ContentContainer>
      <Flex
        as='section'
        alignItems='center'
        justifyContent={{ base: 'center', md: 'space-between' }}
        py={verticalSectionSpacing}
        gap={8}
      >
        <VStack
          spacing={4}
          alignItems={{ base: 'center', md: 'flex-start' }}
          transition='all 1s'
          mb={imageLoaded ? 0 : -100}
          opacity={imageLoaded ? 1 : 0}
          maxW='lg'
        >
          <Heading as='h1' size='home.h1' textAlign={{ base: 'center', md: 'left' }}>
            Send joy to loved ones every month
          </Heading>
          <Text size='home.body' textAlign={{ base: 'center', md: 'left' }}>
            Effortlessly deliver printed photos to your loved ones - every month
          </Text>
          <Link href='/sign-up'>
            <a>
              <Button size='lg'>
                Get your 1<sup>st</sup>&nbsp;month for free
              </Button>
            </a>
          </Link>
        </VStack>
        {width > parseInt(theme.breakpoints.md) * 16 && (
          <Box pos='relative' mb={-verticalSectionSpacing.lg} pointerEvents='none'>
            <Flex pos='relative' zIndex={1}>
              <Flex flexDir='column' justifyContent='space-around'>
                <Box mt={10}>
                  <Image src='/images/photo2.png' width={127} height={87} quality={100} alt='Photo of kid playing' />
                </Box>
                <Box ml={4}>
                  <Image src='/images/photo1.png' width={87} height={120} quality={100} alt='Photo of walking baby' />
                </Box>
              </Flex>
              <VStack>
                <Image src='/images/photo3.png' width={162} height={101} quality={100} alt='Photo of happy family' />
                <Image
                  src='/images/happy-seniors.png'
                  width={367}
                  height={368}
                  alt='Happy seniors looking at photos'
                  quality={100}
                  priority
                  onLoad={() => setImageLoaded(true)}
                />
              </VStack>
              <Flex flexDir='column' justifyContent='space-around'>
                <Box ml={-2} mt={16}>
                  <Image src='/images/photo4.png' width={120} height={160} quality={100} alt='Photo of kid on dad' />
                </Box>
                <Box marginLeft={4}>
                  <Image src='/images/photo5.png' width={134} height={102} quality={100} alt='Photo of baby crawling' />
                </Box>
              </Flex>
            </Flex>
            <Box
              w='258px'
              h='324px'
              bgColor='#F4444425'
              rounded='full'
              pos='absolute'
              left={-100}
              bottom={-100}
              filter='auto'
              blur='200px'
              transform='translate3d(0, 0, 0)'
            />
            <Box
              w='400px'
              h='500px'
              bgColor='#4456F415'
              rounded='full'
              pos='absolute'
              right={-100}
              top={-100}
              filter='auto'
              blur='200px'
              transform='translate3d(0, 0, 0)'
            />
          </Box>
        )}
      </Flex>
    </ContentContainer>
  );
};
