import { FC } from 'react';
import { NextSeo } from 'next-seo';

const description =
  'Subscribe today and send some joy to your loved ones every month. Joyline will print and send your favorite pictures to the people in your life who prefer print over a screen.';

export const Seo: FC<{ title?: string }> = ({ title = 'Joyline | Send printed photos' }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      canonical='https://www.joyline.io/'
      openGraph={{
        url: 'https://www.joyline.io/',
        title: 'Send printed photos to loved ones monthly with Joyline.io',
        description: description,
        images: [
          {
            url: 'https://www.joyline.io/images/og/1200x630.png',
            width: 1200,
            height: 630,
            alt: 'Happy seniors, thanks to Joyline',
            type: 'image/jpeg',
          },
          {
            url: 'https://www.joyline.io/images/og/1200x600.png',
            width: 1200,
            height: 600,
            alt: 'Happy seniors, thanks to Joyline',
            type: 'image/jpeg',
          },
        ],
        site_name: 'Joyline.io',
      }}
      twitter={{
        handle: '@joyline_io',
        site: '@joyline_io',
        cardType: 'summary_large_image',
      }}
    />
  );
};
